<template lang="pug">
.advanced
  .top-dropdown
    v-container
      v-row(justify="center")
        v-col.select_label
          span.text-body-2 選擇賽季：
        v-col.clear(cols="auto", style="padding: 0")
          select.selector(name="seasonInquiry", v-model="seasonInquiryType")
            option(
              :value="index",
              :key="index",
              v-for="(item, index) in leagueData.info.seasons"
            ) {{ item.name }}
  v-container.table_container
    v-row.table_row
      v-col.table.table_col.position-relative.mt-5(cols="12")
        table
          thead
            tr.bottom_head
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(
                :class="{ bdr: title.divider }",
                v-for="(title, index) in personalHeadTitleList",
                :key="index"
              ) {{ title.title }}

          tbody
            tr(v-for="(game, index) in playerSeasonAllData", :key="index")
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0",
                title="整季"
              )

              td {{ getMinutes(game.box.avg_min) }}
              td {{ game.advancement.plus_minus_per_36.toFixed(1) }}
              td {{ (game.advancement.usg * 100).toFixed(1) + '%' }}
              td {{ game.advancement.ppp.toFixed(1) }}
              td {{ (game.advancement.to_rate * 100).toFixed(1) + '%' }}
    v-row.table_row
      v-col.table.table_col.position-relative.mt-5(cols="12")
        table
          thead
            tr.bottom_head
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(
                :class="{ bdr: title.divider }",
                v-for="(title, index) in personalHeadTitleList",
                :key="index"
              ) {{ title.title }}

          tbody
            tr(v-for="(game, index) in playerSeasonDateData", :key="index")
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="game.info.split_info.name"
              )
              td {{ getMinutes(game.box.avg_min) }}
              td {{ game.advancement.plus_minus_per_36.toFixed(1) }}
              td {{ (game.advancement.usg * 100).toFixed(1) + '%' }}
              td {{ game.advancement.ppp.toFixed(1) }}
              td {{ (game.advancement.to_rate * 100).toFixed(1) + '%' }}
    v-row.table_row
      v-col.table.table_col.position-relative.mt-5(cols="12")
        table
          thead
            tr.bottom_head
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(
                :class="{ bdr: title.divider }",
                v-for="(title, index) in personalHeadTitleList",
                :key="index"
              ) {{ title.title }}

          tbody
            tr(
              v-for="(quarter, index) in playerSeasonQuarterCount",
              :key="index",
              v-if="quarter != 1"
            )
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="getQuarterText(quarter - 1)"
              )
              td {{ getMinutes(playerSeasonQuarterData[0].box[quarter - 1].avg_min) }}
              td {{ playerSeasonQuarterData[0].advancement[quarter - 1].plus_minus_per_36.toFixed(1) }}
              td {{ (playerSeasonQuarterData[0].advancement[quarter - 1].usg * 100).toFixed(1) + '%' }}
              td {{ playerSeasonQuarterData[0].advancement[quarter - 1].ppp.toFixed(1) }}
              td {{ (playerSeasonQuarterData[0].advancement[quarter - 1].to_rate * 100).toFixed(1) + '%' }}
    v-row.table_row
      v-col.table.table_col.position-relative.mt-5(cols="12")
        table
          thead
            tr.bottom_head
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(
                :class="{ bdr: title.divider }",
                v-for="(title, index) in personalHeadTitleList",
                :key="index"
              ) {{ title.title }}

          tbody
            tr(v-for="(game, index) in playerSeasonWinLoseData", :key="index")
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="game.info.split == 'WIN' ? '勝' : '負'"
              )
              td {{ getMinutes(game.box.avg_min) }}
              td {{ game.advancement.plus_minus_per_36.toFixed(1) }}
              td {{ (game.advancement.usg * 100).toFixed(1) + '%' }}
              td {{ game.advancement.ppp.toFixed(1) }}
              td {{ (game.advancement.to_rate * 100).toFixed(1) + '%' }}
    v-row.table_row
      v-col.table.table_col.position-relative.mt-5(cols="12")
        table
          thead
            tr.bottom_head
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(
                :class="{ bdr: title.divider }",
                v-for="(title, index) in personalHeadTitleList",
                :key="index"
              ) {{ title.title }}

          tbody
            tr(v-for="(game, index) in playerSeasonVSTeamData", :key="index")
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="'vs. ' + game.info.split_info.name"
              )
              td {{ getMinutes(game.box.avg_min) }}
              td {{ game.advancement.plus_minus_per_36.toFixed(1) }}
              td {{ (game.advancement.usg * 100).toFixed(1) + '%' }}
              td {{ game.advancement.ppp.toFixed(1) }}
              td {{ (game.advancement.to_rate * 100).toFixed(1) + '%' }}
  v-container.tab_container(style="padding: 0")
    v-tabs(
      show-arrows,
      background-color="transparent",
      ripple="false",
      v-model="tab"
    )
      v-tabs-slider(color="primary")

      v-tab(:ripple="false", v-for="(item, index) in tabList", :key="index") {{ item }}
  .offensive_model(v-if="tab == 0")
    v-container(style="margin-top: 20px")
      v-row(justify="end")
        v-col(cols="6", sm="4", md="3", lg="2")
          select.selector(
            name="offensiveTypeInquiryType",
            v-model="offensiveTypeInquiryType"
          )
            option(:value="index", v-for="(item, index) in typeInquiryList") {{ item }}
    v-container.table_container
      v-row.table_row
        v-col.table_col.table.position-relative(cols="12")
          table
            thead.top_head
              tr
                sticky-column.bdl(
                  colspan="1",
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th.bdr(colspan="3") 快攻
                th.bdr(colspan="3") 二波
                th.bdr(colspan="3") 單打
                th.bdr(colspan="3") 擋拆
                th.bdr(colspan="3") 定點投籃
                th(colspan="3") 空切、跟進
            thead
              tr.bottom_head
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th(
                  :class="{ bdr: title.divider }",
                  v-for="(title, index) in offensiveTypeHeadTitleList",
                  :key="index"
                ) {{ title.title }}
            tbody
              tr(
                v-for="(game, index) in playerSeasonOffensiveTypeData",
                :key="index"
              )
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0",
                  :title="getTitle(game)"
                )
                td {{ (game.player_play_type.transition.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.transition.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.transition.to_rate * 100).toFixed(1) + '%' }}
                td {{ (game.player_play_type.second_chance.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.second_chance.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.second_chance.to_rate * 100).toFixed(1) + '%' }}
                td {{ (game.player_play_type.iso.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.iso.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.iso.to_rate * 100).toFixed(1) + '%' }}
                td {{ (game.player_play_type.pnr.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.pnr.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.pnr.to_rate * 100).toFixed(1) + '%' }}
                td {{ (game.player_play_type.spot_up.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.spot_up.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.spot_up.to_rate * 100).toFixed(1) + '%' }}
                td {{ (game.player_play_type.off_ball.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.off_ball.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.off_ball.to_rate * 100).toFixed(1) + '%' }}
      v-row
        v-col.mt-15(
          cols="12",
          v-for="(game, index) in playerSeasonOffensiveTypeData",
          :key="index"
        )
          .chart_title.text-center {{ getTitle(game) }}
          high-chart-combo-percent-bar(
            :barDataList="getPlayerSeasonOffensiveTypeDataList(game)",
            :categories="offensiveTypeSeasonCategoryList"
          )
  .pass_type(v-if="tab == 1")
    v-container(style="margin-top: 20px")
      v-row(justify="end")
        v-col(cols="6", sm="4", md="3", lg="2")
          select.selector(
            name="passTypeInquiryType",
            v-model="passTypeInquiryType"
          )
            option(:value="index", v-for="(item, index) in typeInquiryList") {{ item }}
    v-container.table_container
      v-row.table_row
        v-col.table_col.table.position-relative(cols="12")
          table
            thead.top_head
              tr
                sticky-column.bdl(
                  colspan="1",
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th.bdr(colspan="2") 快攻傳球
                th.bdr(colspan="2") 二波傳球
                th.bdr(colspan="2") 單打傳球
                th.bdr(colspan="2") 擋拆傳球
                th(colspan="2") 其他傳球
            thead
              tr.bottom_head
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th(
                  :class="{ bdr: title.divider }",
                  v-for="(title, index) in passTypeHeadTitleList",
                  :key="index"
                ) {{ title.title }}
            tbody
              tr(
                v-for="(game, index) in playerSeasonPassTypeData",
                :key="index"
              )
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0",
                  :title="getTitle(game)"
                )
                td {{ ((game.pass_type.transition_pass.play / (game.pass_type.transition_pass.play + game.pass_type.second_chance_pass.play + game.pass_type.iso_pass.play + game.pass_type.pnr_pass.play + game.pass_type.other_pass.play)) * 100).toFixed(1) + '%' }}
                td.bdr {{ game.pass_type.transition_pass.ppp.toFixed(1) }}
                td {{ ((game.pass_type.second_chance_pass.play / (game.pass_type.transition_pass.play + game.pass_type.second_chance_pass.play + game.pass_type.iso_pass.play + game.pass_type.pnr_pass.play + game.pass_type.other_pass.play)) * 100).toFixed(1) + '%' }}
                td.bdr {{ game.pass_type.second_chance_pass.ppp.toFixed(1) }}
                td {{ ((game.pass_type.iso_pass.play / (game.pass_type.transition_pass.play + game.pass_type.second_chance_pass.play + game.pass_type.iso_pass.play + game.pass_type.pnr_pass.play + game.pass_type.other_pass.play)) * 100).toFixed(1) + '%' }}
                td.bdr {{ game.pass_type.iso_pass.ppp.toFixed(1) }}
                td {{ ((game.pass_type.pnr_pass.play / (game.pass_type.transition_pass.play + game.pass_type.second_chance_pass.play + game.pass_type.iso_pass.play + game.pass_type.pnr_pass.play + game.pass_type.other_pass.play)) * 100).toFixed(1) + '%' }}
                td.bdr {{ game.pass_type.pnr_pass.ppp.toFixed(1) }}
                td {{ ((game.pass_type.other_pass.play / (game.pass_type.transition_pass.play + game.pass_type.second_chance_pass.play + game.pass_type.iso_pass.play + game.pass_type.pnr_pass.play + game.pass_type.other_pass.play)) * 100).toFixed(1) + '%' }}
                td.bdr {{ game.pass_type.other_pass.ppp.toFixed(1) }}
      v-row
        v-col.mt-15(
          cols="12",
          v-for="(game, index) in playerSeasonPassTypeData",
          :key="index"
        )
          .chart_title.text-center {{ getTitle(game) }}
          high-chart-combo-percent-bar(
            :barDataList="getPlayerSeasonPassTypeDataList(game)",
            :categories="passTypeSeasonCategoryList"
          )
  .shot_state(v-if="tab == 2")
    v-container(style="margin-top: 20px")
      v-row(justify="end")
        v-col(cols="6", sm="4", md="3", lg="2")
          select.selector(
            name="shotPercentInquiryType",
            v-model="shotPercentInquiryType"
          )
            option(:value="index", v-for="(item, index) in typeInquiryList") {{ item }}
    v-container.table_container
      v-row.table_row
        v-col.table_col.table.position-relative(cols="12")
          table
            thead.top_head
              tr
                sticky-column.bdl(
                  colspan="1",
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th.bdr(colspan="2") 禁區出手
                th.bdr(colspan="2") 外線空擋
                th.bdr(colspan="2") 外線干擾
                th.bdr(colspan="2") 失誤
                th.bdr(colspan="2") 上罰球線
            thead
              tr.bottom_head
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th(
                  :class="{ bdr: title.divider }",
                  v-for="(title, index) in shotPercentHeadTitleList",
                  :key="index"
                ) {{ title.title }}
            tbody
              tr(
                v-for="(game, index) in playerSeasonShotPercentData",
                :key="index"
              )
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0",
                  :title="getTitle(game)"
                )
                td {{ (game.shot_state.shot_state_paint.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_state.shot_state_paint.ppp.toFixed(1) }}
                td {{ (game.shot_state.shot_state_open.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_state.shot_state_open.ppp.toFixed(1) }}
                td {{ (game.shot_state.shot_state_contested.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_state.shot_state_contested.ppp.toFixed(1) }}
                td {{ (game.shot_state.shot_state_to.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_state.shot_state_to.ppp.toFixed(1) }}
                td {{ (game.shot_state.shot_state_draw_shooting_foul.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_state.shot_state_draw_shooting_foul.ppp.toFixed(1) }}
      v-row
        v-col.mt-15(
          cols="12",
          v-for="(game, index) in playerSeasonShotPercentData",
          :key="index"
        )
          .chart_title.text-center {{ getTitle(game) }}
          high-chart-combo-percent-bar(
            :barDataList="getPlayerSeasonShotPercentDataList(game)",
            :categories="shotPercentSeasonCategoryList"
          )

  .shot_type(v-if="tab == 3")
    v-container(style="margin-top: 20px")
      v-row(justify="end")
        v-col(cols="6", sm="4", md="3", lg="2")
          select.selector(
            name="shotTypeInquiryType",
            v-model="shotTypeInquiryType"
          )
            option(:value="index", v-for="(item, index) in typeInquiryList") {{ item }}
    v-container.table_container
      v-row.table_row
        v-col.table_col.table.position-relative(cols="12")
          table
            thead.top_head
              tr
                sticky-column.bdl(
                  colspan="1",
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th.bdr(colspan="2") 接球投籃
                th.bdr(colspan="2") 運球投籃
                th.bdr(colspan="2") 拋投
                th.bdr(colspan="2") 上籃
                th.bdr(colspan="2") 籃下投籃
                th.bdr(colspan="2") 灌籃
            thead
              tr.bottom_head
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th(
                  :class="{ bdr: title.divider }",
                  v-for="(title, index) in shotTypeHeadTitleList",
                  :key="index"
                ) {{ title.title }}

            tbody
              tr(
                v-for="(game, index) in playerSeasonShotTypeData",
                :key="index"
              )
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0",
                  :title="getTitle(game)"
                )
                td {{ (game.shot_type.catch_and_shoot.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_type.catch_and_shoot.ppp.toFixed(1) }}
                td {{ (game.shot_type.jumper.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_type.jumper.ppp.toFixed(1) }}
                td {{ (game.shot_type.floater.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_type.floater.ppp.toFixed(1) }}
                td {{ (game.shot_type.layup.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_type.layup.ppp.toFixed(1) }}
                td {{ (game.shot_type.rim.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_type.rim.ppp.toFixed(1) }}
                td {{ (game.shot_type.dunk.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_type.dunk.ppp.toFixed(1) }}
      v-row
        v-col.mt-15(
          cols="12",
          v-for="(game, index) in playerSeasonShotTypeData",
          :key="index"
        )
          .chart_title.text-center {{ getTitle(game) }}
          high-chart-combo-percent-bar(
            :barDataList="getPlayerSeasonShotTypeDataList(game)",
            :categories="shotTypeSeasonCategoryList"
          )
  .play_type(v-if="tab == 4")
    v-container(style="margin-top: 20px")
      v-row(justify="end")
        v-col(cols="6", sm="4", md="3", lg="2")
          select.selector(
            name="offensiveChooseInquiryType",
            v-model="offensiveChooseInquiryType"
          )
            option(:value="index", v-for="(item, index) in typeInquiryList") {{ item }}
    v-container.table_container
      v-row.table_row
        v-col.table_col.table.position-relative(cols="12")
          table
            thead.top_head
              tr
                sticky-column.bdl(
                  colspan="1",
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th.bdr(colspan="2") 單打後持球者出手
                th.bdr(colspan="2") 單打後傳球
                th.bdr(colspan="2") 擋拆後持球者出手
                th.bdr(colspan="2") 擋拆後傳掩護者
                th.bdr(colspan="2") 擋拆後傳其他人
            thead
              tr.bottom_head
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th(
                  :class="{ bdr: title.divider }",
                  v-for="(title, index) in offensiveChooseHeadTitleList",
                  :key="index"
                ) {{ title.title }}

            tbody
              tr(
                v-for="(game, index) in playerSeasonOffensiveChooseData",
                :key="index"
              )
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0",
                  :title="getTitle(game)"
                )
                td {{ ((game.play_type.iso_ball_handler.play / (game.play_type.iso_ball_handler.play + game.play_type.iso_ball_others.play)) * 100).toFixed(1) + '%' }}
                td.bdr {{ game.play_type.iso_ball_handler.ppp.toFixed(1) }}
                td {{ ((game.play_type.iso_ball_others.play / (game.play_type.iso_ball_handler.play + game.play_type.iso_ball_others.play)) * 100).toFixed(1) + '%' }}
                td.bdr {{ game.play_type.iso_ball_others.ppp.toFixed(1) }}
                td {{ ((game.play_type.pnr_ball_handler.play / (game.play_type.pnr_ball_handler.play + game.play_type.pnr_ball_screener.play + game.play_type.pnr_ball_others.play)) * 100).toFixed(1) + '%' }}
                td.bdr {{ game.play_type.pnr_ball_handler.ppp.toFixed(1) }}
                td {{ ((game.play_type.pnr_ball_screener.play / (game.play_type.pnr_ball_handler.play + game.play_type.pnr_ball_screener.play + game.play_type.pnr_ball_others.play)) * 100).toFixed(1) + '%' }}
                td.bdr {{ game.play_type.pnr_ball_screener.ppp.toFixed(1) }}
                td {{ ((game.play_type.pnr_ball_others.play / (game.play_type.pnr_ball_handler.play + game.play_type.pnr_ball_screener.play + game.play_type.pnr_ball_others.play)) * 100).toFixed(1) + '%' }}
                td.bdr {{ game.play_type.pnr_ball_others.ppp.toFixed(1) }}
      v-row
        v-col.mt-15(
          cols="12",
          v-for="(game, index) in playerSeasonOffensiveChooseData",
          :key="index"
        )
          .chart_title.text-center {{ getTitle(game) }}
          high-chart-combo-percent-bar(
            :barDataList="getPlayerSeasonOffensiveChooseDataList(game)",
            :categories="offensiveChooseSeasonCategoryList"
          )
</template>
<script>
import PositionField from "@/components/common/PositionField";
import StickyColumn from "@/components/common/Table/StickyColumn";
import HighChartComboPercentBar from "@/components/common/HighChartComboPercentBar";
import { getLeagueList } from "@/api/league";
import { getPlayerSeasonData } from "@/api/player";

export default {
  name: "LeaguePlayerAdvanced",
  components: {
    PositionField,
    StickyColumn,
    HighChartComboPercentBar,
  },
  data() {
    return {
      league: this.$route.params.league,
      leagueData: {
        info: {},
      },
      playerID: this.$route.params.id,
      seasonInquiryType: 0,

      personalHeadTitleList: [
        {
          title: "時間",
          divider: false,
        },
        {
          title: "正負值per 36",
          divider: false,
        },
        {
          title: "USG%",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: false,
        },
        {
          title: "失誤率",
          divider: false,
        },
      ],

      playerSeasonAllData: [],
      playerSeasonDateData: [],
      playerSeasonQuarterData: [],
      playerSeasonQuarterCount: 0,
      playerSeasonWinLoseData: [],
      playerSeasonVSTeamData: [],

      tab: 0,
      tabList: ["進攻模式", "傳球", "出手比例", "出手動作", "進攻選擇"],

      typeInquiryList: ["整季", "依賽別", "依節次", "依勝負", "依對手"],

      offensiveTypeInquiryType: null,
      playerSeasonOffensiveTypeData: [],
      offensiveTypeHeadTitleList: [
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: false,
        },
        {
          title: "失誤率",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: false,
        },
        {
          title: "失誤率",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: false,
        },
        {
          title: "失誤率",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: false,
        },
        {
          title: "失誤率",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: false,
        },
        {
          title: "失誤率",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: false,
        },
        {
          title: "失誤率",
          divider: false,
        },
      ],
      offensiveTypeSeasonCategoryList: [
        "快攻",
        "二波",
        "單打",
        "擋拆",
        "定點投籃",
        "空切、跟進",
      ],

      passTypeInquiryType: null,
      playerSeasonPassTypeData: [],
      passTypeHeadTitleList: [
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
      ],
      passTypeSeasonCategoryList: [
        "快攻傳球",
        "二波傳球",
        "單打傳球",
        "擋拆傳球",
        "其他傳球",
      ],

      shotPercentInquiryType: null,
      playerSeasonShotPercentData: [],
      shotPercentHeadTitleList: [
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
      ],
      shotPercentSeasonCategoryList: [
        "禁區出手",
        "外線空擋",
        "外線干擾",
        "失誤",
        "上罰球線",
      ],

      shotTypeInquiryType: null,
      playerSeasonShotTypeData: [],
      shotTypeHeadTitleList: [
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
      ],
      shotTypeSeasonCategoryList: [
        "接球投籃",
        "運球投籃",
        "拋投",
        "上籃",
        "籃下投籃",
        "灌籃",
      ],

      offensiveChooseInquiryType: null,
      playerSeasonOffensiveChooseData: [],
      offensiveChooseHeadTitleList: [
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
        { title: "比例", divider: false },
        { title: "進攻效率", divider: true },
      ],
      offensiveChooseSeasonCategoryList: [
        "單打後持球者出手",
        "單打後傳球",
        "擋拆後持球者出手",
        "擋拆後傳掩護者",
        "擋拆後傳其他人",
      ],
    };
  },
  watch: {
    seasonInquiryType() {
      this.playerSeasonAllData = [];
      this.playerSeasonDateData = [];
      this.playerSeasonQuarterData = [];
      this.playerSeasonQuarterCount = 0;
      this.playerSeasonWinLoseData = [];
      this.playerSeasonVSTeamData = [];
      this.tab = 0;
      this.offensiveTypeInquiryType = null;
      this.playerSeasonOffensiveTypeData = [];
      this.passTypeInquiryType = null;
      this.playerSeasonPassTypeData = [];
      this.shotPercentInquiryType = null;
      this.playerSeasonShotPercentData = [];
      this.shotTypeInquiryType = null;
      this.playerSeasonShotTypeData = [];
      this.offensiveChooseInquiryType = null;
      this.playerSeasonOffensiveChooseData = [];

      this.getPlayerSeasonAllData();
      this.getPlayerSeasonDateData();
      this.getPlayerSeasonQuarterData();
      this.getPlayerSeasonWinLoseData();
      this.getPlayerSeasonVSTeamData();

      this.$nextTick(() => {
        this.offensiveTypeInquiryType = 0;
      });
    },
    tab() {
      this.playerSeasonOffensiveTypeData = [];
      this.playerSeasonPassTypeData = [];
      this.playerSeasonShotPercentData = [];
      this.playerSeasonShotTypeData = [];
      this.playerSeasonOffensiveChooseData = [];
      switch (this.tab) {
        case 0:
          this.offensiveTypeInquiryType = 0;
          this.passTypeInquiryType = null;
          this.shotPercentInquiryType = null;
          this.shotTypeInquiryType = null;
          this.offensiveChooseInquiryType = null;
          break;
        case 1:
          this.offensiveTypeInquiryType = null;
          this.passTypeInquiryType = 0;
          this.shotPercentInquiryType = null;
          this.shotTypeInquiryType = null;
          this.offensiveChooseInquiryType = null;
          break;
        case 2:
          this.offensiveTypeInquiryType = null;
          this.passTypeInquiryType = null;
          this.shotPercentInquiryType = 0;
          this.shotTypeInquiryType = null;
          this.offensiveChooseInquiryType = null;
          break;
        case 3:
          this.offensiveTypeInquiryType = null;
          this.passTypeInquiryType = null;
          this.shotPercentInquiryType = null;
          this.shotTypeInquiryType = 0;
          this.offensiveChooseInquiryType = null;
          break;
        case 4:
          this.offensiveTypeInquiryType = null;
          this.passTypeInquiryType = null;
          this.shotPercentInquiryType = null;
          this.shotTypeInquiryType = null;
          this.offensiveChooseInquiryType = 0;
          break;
      }
    },
    offensiveTypeInquiryType() {
      this.playerSeasonOffensiveTypeData = [];
      switch (this.offensiveTypeInquiryType) {
        case 0:
          this.getPlayerSeasonOffensiveTypeData("NONE");
          break;
        case 1:
          this.getPlayerSeasonOffensiveTypeData("DATE");
          break;
        case 2:
          this.getPlayerSeasonOffensiveTypeData("QUARTER");
          break;
        case 3:
          this.getPlayerSeasonOffensiveTypeData("WIN_LOSE");
          break;
        case 4:
          this.getPlayerSeasonOffensiveTypeData("VS_TEAM");
          break;
      }
    },
    passTypeInquiryType() {
      this.playerSeasonPassTypeData = [];
      switch (this.passTypeInquiryType) {
        case 0:
          this.getPlayerSeasonPassTypeData("NONE");
          break;
        case 1:
          this.getPlayerSeasonPassTypeData("DATE");
          break;
        case 2:
          this.getPlayerSeasonPassTypeData("QUARTER");
          break;
        case 3:
          this.getPlayerSeasonPassTypeData("WIN_LOSE");
          break;
        case 4:
          this.getPlayerSeasonPassTypeData("VS_TEAM");
          break;
      }
    },
    shotPercentInquiryType() {
      this.playerSeasonShotPercentData = [];
      switch (this.shotPercentInquiryType) {
        case 0:
          this.getPlayerSeasonShotPercentData("NONE");
          break;
        case 1:
          this.getPlayerSeasonShotPercentData("DATE");
          break;
        case 2:
          this.getPlayerSeasonShotPercentData("QUARTER");
          break;
        case 3:
          this.getPlayerSeasonShotPercentData("WIN_LOSE");
          break;
        case 4:
          this.getPlayerSeasonShotPercentData("VS_TEAM");
          break;
      }
    },
    shotTypeInquiryType() {
      this.playerSeasonShotTypeData = [];
      switch (this.shotTypeInquiryType) {
        case 0:
          this.getPlayerSeasonShotTypeData("NONE");
          break;
        case 1:
          this.getPlayerSeasonShotTypeData("DATE");
          break;
        case 2:
          this.getPlayerSeasonShotTypeData("QUARTER");
          break;
        case 3:
          this.getPlayerSeasonShotTypeData("WIN_LOSE");
          break;
        case 4:
          this.getPlayerSeasonShotTypeData("VS_TEAM");
          break;
      }
    },
    offensiveChooseInquiryType() {
      this.playerSeasonOffensiveChooseData = [];
      switch (this.offensiveChooseInquiryType) {
        case 0:
          this.getPlayerSeasonOffensiveChooseData("NONE");
          break;
        case 1:
          this.getPlayerSeasonOffensiveChooseData("DATE");
          break;
        case 2:
          this.getPlayerSeasonOffensiveChooseData("QUARTER");
          break;
        case 3:
          this.getPlayerSeasonOffensiveChooseData("WIN_LOSE");
          break;
        case 4:
          this.getPlayerSeasonOffensiveChooseData("VS_TEAM");
          break;
      }
    },
  },
  created() {
    this.getLeagueList();
  },
  methods: {
    getLeagueList() {
      getLeagueList().then((response) => {
        for (let index in response.data) {
          let temp = response.data[index];
          if (temp.name.indexOf(this.league) != -1) {
            this.leagueData = Object.assign({}, temp);
          }
        }
        this.getPlayerSeasonAllData();
        this.getPlayerSeasonDateData();
        this.getPlayerSeasonQuarterData();
        this.getPlayerSeasonWinLoseData();
        this.getPlayerSeasonVSTeamData();

        this.offensiveTypeInquiryType = 0;
      });
    },
    getPlayerSeasonAllData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, advancement, box",
        player_id: this.playerID,
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonAllData = response.data;
      });
    },
    getPlayerSeasonDateData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, advancement, box",
        player_id: this.playerID,
        split_type: "DATE",
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonDateData = response.data;
      });
    },
    getPlayerSeasonQuarterData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, advancement, box",
        player_id: this.playerID,
        show_all_quarters: true,
        split_type: "NONE",
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonQuarterData = response.data;
        this.playerSeasonQuarterCount =
          this.playerSeasonQuarterData[0].box.length;
      });
    },
    getPlayerSeasonWinLoseData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, advancement, box",
        player_id: this.playerID,
        split_type: "WIN_LOSE",
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonWinLoseData = response.data;
      });
    },
    getPlayerSeasonVSTeamData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, advancement, box",
        player_id: this.playerID,
        split_type: "VS_TEAM",
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonVSTeamData = response.data;
      });
    },
    getPlayerSeasonOffensiveTypeData(split) {
      if ("QUARTER" == split) {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, player_play_type",
          player_id: this.playerID,
          split_type: "NONE",
          show_all_quarters: true,
        };
        getPlayerSeasonData(temp).then((response) => {
          let tempList = response.data;
          let count = tempList[0].player_play_type.iso.length;
          for (let i = 1; i < count; i++) {
            let temp = {
              quarter: i,
              info: tempList[0].info,
              player_play_type: {
                iso: tempList[0].player_play_type.iso[i],
                off_ball: tempList[0].player_play_type.off_ball[i],
                pnr: tempList[0].player_play_type.pnr[i],
                second_chance: tempList[0].player_play_type.second_chance[i],
                spot_up: tempList[0].player_play_type.spot_up[i],
                transition: tempList[0].player_play_type.transition[i],
              },
            };
            this.playerSeasonOffensiveTypeData.push(temp);
          }
        });
      } else {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, player_play_type",
          player_id: this.playerID,
          split_type: split,
        };
        getPlayerSeasonData(temp).then((response) => {
          this.playerSeasonOffensiveTypeData = response.data;
        });
      }
    },
    getPlayerSeasonPassTypeData(split) {
      if ("QUARTER" == split) {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, pass_type",
          player_id: this.playerID,
          split_type: "NONE",
          show_all_quarters: true,
        };
        getPlayerSeasonData(temp).then((response) => {
          let tempList = response.data;
          let count = tempList[0].pass_type.transition_pass.length;
          for (let i = 1; i < count; i++) {
            let temp = {
              quarter: i,
              info: tempList[0].info,
              pass_type: {
                iso_ball_handler_pass:
                  tempList[0].pass_type.iso_ball_handler_pass[i],
                iso_ball_others_pass:
                  tempList[0].pass_type.iso_ball_others_pass[i],
                iso_pass: tempList[0].pass_type.iso_pass[i],
                other_pass: tempList[0].pass_type.other_pass[i],
                pnr_ball_handler_pass:
                  tempList[0].pass_type.pnr_ball_handler_pass[i],
                pnr_ball_others_pass:
                  tempList[0].pass_type.pnr_ball_others_pass[i],
                pnr_ball_screener_pass:
                  tempList[0].pass_type.pnr_ball_screener_pass[i],
                pnr_pass: tempList[0].pass_type.pnr_pass[i],
                second_chance_pass: tempList[0].pass_type.second_chance_pass[i],
                transition_pass: tempList[0].pass_type.transition_pass[i],
              },
            };
            this.playerSeasonPassTypeData.push(temp);
          }
        });
      } else {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, pass_type",
          player_id: this.playerID,
          split_type: split,
        };
        getPlayerSeasonData(temp).then((response) => {
          this.playerSeasonPassTypeData = response.data;
        });
      }
    },
    getPlayerSeasonShotPercentData(split) {
      if ("QUARTER" == split) {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, shot_state",
          player_id: this.playerID,
          split_type: "NONE",
          show_all_quarters: true,
        };
        getPlayerSeasonData(temp).then((response) => {
          let tempList = response.data;
          let count = tempList[0].shot_state.shot_state_to.length;
          for (let i = 1; i < count; i++) {
            let temp = {
              quarter: i,
              info: tempList[0].info,
              shot_state: {
                shot_state_to: tempList[0].shot_state.shot_state_to[i],
                shot_state_contested:
                  tempList[0].shot_state.shot_state_contested[i],
                shot_state_draw_shooting_foul:
                  tempList[0].shot_state.shot_state_draw_shooting_foul[i],
                shot_state_open: tempList[0].shot_state.shot_state_open[i],
                shot_state_paint: tempList[0].shot_state.shot_state_paint[i],
              },
            };
            this.playerSeasonShotPercentData.push(temp);
          }
        });
      } else {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, shot_state",
          player_id: this.playerID,
          split_type: split,
        };
        getPlayerSeasonData(temp).then((response) => {
          this.playerSeasonShotPercentData = response.data;
        });
      }
    },
    getPlayerSeasonShotTypeData(split) {
      if ("QUARTER" == split) {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, shot_type",
          player_id: this.playerID,
          split_type: "NONE",
          show_all_quarters: true,
        };
        getPlayerSeasonData(temp).then((response) => {
          let tempList = response.data;
          let count = tempList[0].shot_type.catch_and_shoot.length;
          for (let i = 1; i < count; i++) {
            let temp = {
              quarter: i,
              info: tempList[0].info,
              shot_type: {
                catch_and_shoot: tempList[0].shot_type.catch_and_shoot[i],
                jumper: tempList[0].shot_type.jumper[i],
                floater: tempList[0].shot_type.floater[i],
                layup: tempList[0].shot_type.layup[i],
                rim: tempList[0].shot_type.rim[i],
                dunk: tempList[0].shot_type.dunk[i],
              },
            };
            this.playerSeasonShotTypeData.push(temp);
          }
        });
      } else {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, shot_type",
          player_id: this.playerID,
          split_type: split,
        };
        getPlayerSeasonData(temp).then((response) => {
          this.playerSeasonShotTypeData = response.data;
        });
      }
    },
    getPlayerSeasonOffensiveChooseData(split) {
      if ("QUARTER" == split) {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, play_type",
          player_id: this.playerID,
          split_type: "NONE",
          show_all_quarters: true,
        };
        getPlayerSeasonData(temp).then((response) => {
          let tempList = response.data;
          let count = tempList[0].play_type.iso_ball_handler.length;
          for (let i = 1; i < count; i++) {
            let temp = {
              quarter: i,
              info: tempList[0].info,
              play_type: {
                iso_ball_handler: tempList[0].play_type.iso_ball_handler[i],
                iso_ball_others: tempList[0].play_type.iso_ball_others[i],
                pnr_ball_handler: tempList[0].play_type.pnr_ball_handler[i],
                pnr_ball_screener: tempList[0].play_type.pnr_ball_screener[i],
                pnr_ball_others: tempList[0].play_type.pnr_ball_others[i],
              },
            };
            this.playerSeasonOffensiveChooseData.push(temp);
          }
        });
      } else {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, play_type",
          player_id: this.playerID,
          split_type: split,
        };
        getPlayerSeasonData(temp).then((response) => {
          this.playerSeasonOffensiveChooseData = response.data;
        });
      }
    },
    getMinutes(temp) {
      let min = parseInt(temp / 60);
      let second = (temp % 60).toFixed(0);
      if (second < 10) {
        return min + ":0" + second;
      }
      return min + ":" + second;
    },
    getQuarterText(count) {
      if (count == 1) {
        return "第一節";
      } else if (count == 2) {
        return "第二節";
      } else if (count == 3) {
        return "第三節";
      } else if (count == 4) {
        return "第四節";
      } else {
        return "OT" + (count - 4);
      }
    },
    getTitle(game) {
      let temp;
      if (this.tab == 0) {
        temp = this.offensiveTypeInquiryType;
      } else if (this.tab == 1) {
        temp = this.passTypeInquiryType;
      } else if (this.tab == 2) {
        temp = this.shotPercentInquiryType;
      } else if (this.tab == 3) {
        temp = this.shotTypeInquiryType;
      } else if (this.tab == 4) {
        temp = this.offensiveChooseInquiryType;
      }
      switch (temp) {
        case 0:
          return "整季";
        case 1:
          return game.info.split_info.name;
        case 2:
          return this.getQuarterText(game.quarter);
        case 3:
          return game.info.split == "WIN" ? "勝" : "負";
        case 4:
          return "vs." + game.info.split_info.name;
      }
    },
    getPlayerSeasonOffensiveTypeDataList(game) {
      let tempPlayerSeasonOffensiveTypeDataList = [];
      tempPlayerSeasonOffensiveTypeDataList.push({
        name: "比例",
        data: [],
        color: "#94B7D4",
      });
      tempPlayerSeasonOffensiveTypeDataList.push({
        name: "進攻效率",
        data: [],
        color: "#487AA4",
      });
      tempPlayerSeasonOffensiveTypeDataList.push({
        name: "失誤率",
        data: [],
        color: "#FFBB50",
      });

      tempPlayerSeasonOffensiveTypeDataList[0].data.push(
        game.player_play_type.transition.rate
      );
      tempPlayerSeasonOffensiveTypeDataList[0].data.push(
        game.player_play_type.second_chance.rate
      );
      tempPlayerSeasonOffensiveTypeDataList[0].data.push(
        game.player_play_type.iso.rate
      );
      tempPlayerSeasonOffensiveTypeDataList[0].data.push(
        game.player_play_type.pnr.rate
      );
      tempPlayerSeasonOffensiveTypeDataList[0].data.push(
        game.player_play_type.spot_up.rate
      );
      tempPlayerSeasonOffensiveTypeDataList[0].data.push(
        game.player_play_type.off_ball.rate
      );
      tempPlayerSeasonOffensiveTypeDataList[1].data.push(
        Number(game.player_play_type.transition.ppp.toFixed(1))
      );
      tempPlayerSeasonOffensiveTypeDataList[1].data.push(
        Number(game.player_play_type.second_chance.ppp.toFixed(1))
      );
      tempPlayerSeasonOffensiveTypeDataList[1].data.push(
        Number(game.player_play_type.iso.ppp.toFixed(1))
      );
      tempPlayerSeasonOffensiveTypeDataList[1].data.push(
        Number(game.player_play_type.pnr.ppp.toFixed(1))
      );
      tempPlayerSeasonOffensiveTypeDataList[1].data.push(
        Number(game.player_play_type.spot_up.ppp.toFixed(1))
      );
      tempPlayerSeasonOffensiveTypeDataList[1].data.push(
        Number(game.player_play_type.off_ball.ppp.toFixed(1))
      );
      tempPlayerSeasonOffensiveTypeDataList[2].data.push(
        game.player_play_type.transition.to_rate
      );
      tempPlayerSeasonOffensiveTypeDataList[2].data.push(
        game.player_play_type.second_chance.to_rate
      );
      tempPlayerSeasonOffensiveTypeDataList[2].data.push(
        game.player_play_type.iso.to_rate
      );
      tempPlayerSeasonOffensiveTypeDataList[2].data.push(
        game.player_play_type.pnr.to_rate
      );
      tempPlayerSeasonOffensiveTypeDataList[2].data.push(
        game.player_play_type.spot_up.to_rate
      );
      tempPlayerSeasonOffensiveTypeDataList[2].data.push(
        game.player_play_type.off_ball.to_rate
      );

      return tempPlayerSeasonOffensiveTypeDataList;
    },
    getPlayerSeasonPassTypeDataList(game) {
      let tempPlayerSeasonPassTypeDataList = [];

      tempPlayerSeasonPassTypeDataList.push({
        name: "比例",
        data: [],
        color: "#94B7D4",
      });
      tempPlayerSeasonPassTypeDataList.push({
        name: "進攻效率",
        data: [],
        color: "#487AA4",
      });

      tempPlayerSeasonPassTypeDataList[0].data.push(
        game.pass_type.transition_pass.play /
          (game.pass_type.transition_pass.play +
            game.pass_type.second_chance_pass.play +
            game.pass_type.iso_pass.play +
            game.pass_type.pnr_pass.play +
            game.pass_type.other_pass.play)
      );
      tempPlayerSeasonPassTypeDataList[0].data.push(
        game.pass_type.second_chance_pass.play /
          (game.pass_type.transition_pass.play +
            game.pass_type.second_chance_pass.play +
            game.pass_type.iso_pass.play +
            game.pass_type.pnr_pass.play +
            game.pass_type.other_pass.play)
      );
      tempPlayerSeasonPassTypeDataList[0].data.push(
        game.pass_type.iso_pass.play /
          (game.pass_type.transition_pass.play +
            game.pass_type.second_chance_pass.play +
            game.pass_type.iso_pass.play +
            game.pass_type.pnr_pass.play +
            game.pass_type.other_pass.play)
      );
      tempPlayerSeasonPassTypeDataList[0].data.push(
        game.pass_type.pnr_pass.play /
          (game.pass_type.transition_pass.play +
            game.pass_type.second_chance_pass.play +
            game.pass_type.iso_pass.play +
            game.pass_type.pnr_pass.play +
            game.pass_type.other_pass.play)
      );
      tempPlayerSeasonPassTypeDataList[0].data.push(
        game.pass_type.other_pass.play /
          (game.pass_type.transition_pass.play +
            game.pass_type.second_chance_pass.play +
            game.pass_type.iso_pass.play +
            game.pass_type.pnr_pass.play +
            game.pass_type.other_pass.play)
      );
      tempPlayerSeasonPassTypeDataList[1].data.push(
        Number(game.pass_type.transition_pass.ppp.toFixed(1))
      );
      tempPlayerSeasonPassTypeDataList[1].data.push(
        Number(game.pass_type.second_chance_pass.ppp.toFixed(1))
      );
      tempPlayerSeasonPassTypeDataList[1].data.push(
        Number(game.pass_type.iso_pass.ppp.toFixed(1))
      );
      tempPlayerSeasonPassTypeDataList[1].data.push(
        Number(game.pass_type.pnr_pass.ppp.toFixed(1))
      );
      tempPlayerSeasonPassTypeDataList[1].data.push(
        Number(game.pass_type.other_pass.ppp.toFixed(1))
      );

      return tempPlayerSeasonPassTypeDataList;
    },
    getPlayerSeasonShotPercentDataList(game) {
      let tempPlayerSeasonShotPercentDataList = [];

      tempPlayerSeasonShotPercentDataList.push({
        name: "比例",
        data: [],
        color: "#94B7D4",
      });
      tempPlayerSeasonShotPercentDataList.push({
        name: "進攻效率",
        data: [],
        color: "#487AA4",
      });

      tempPlayerSeasonShotPercentDataList[0].data.push(
        game.shot_state.shot_state_paint.rate
      );
      tempPlayerSeasonShotPercentDataList[0].data.push(
        game.shot_state.shot_state_open.rate
      );
      tempPlayerSeasonShotPercentDataList[0].data.push(
        game.shot_state.shot_state_contested.rate
      );
      tempPlayerSeasonShotPercentDataList[0].data.push(
        game.shot_state.shot_state_to.rate
      );
      tempPlayerSeasonShotPercentDataList[0].data.push(
        game.shot_state.shot_state_draw_shooting_foul.rate
      );
      tempPlayerSeasonShotPercentDataList[1].data.push(
        Number(game.shot_state.shot_state_paint.ppp.toFixed(1))
      );
      tempPlayerSeasonShotPercentDataList[1].data.push(
        Number(game.shot_state.shot_state_open.ppp.toFixed(1))
      );
      tempPlayerSeasonShotPercentDataList[1].data.push(
        Number(game.shot_state.shot_state_contested.ppp.toFixed(1))
      );
      tempPlayerSeasonShotPercentDataList[1].data.push(
        Number(game.shot_state.shot_state_to.ppp.toFixed(1))
      );
      tempPlayerSeasonShotPercentDataList[1].data.push(
        Number(game.shot_state.shot_state_draw_shooting_foul.ppp.toFixed(1))
      );

      return tempPlayerSeasonShotPercentDataList;
    },
    getPlayerSeasonShotTypeDataList(game) {
      let tempPlayerSeasonShotTypeDataList = [];

      tempPlayerSeasonShotTypeDataList.push({
        name: "比例",
        data: [],
        color: "#94B7D4",
      });
      tempPlayerSeasonShotTypeDataList.push({
        name: "進攻效率",
        data: [],
        color: "#487AA4",
      });

      tempPlayerSeasonShotTypeDataList[0].data.push(
        game.shot_type.catch_and_shoot.rate
      );
      tempPlayerSeasonShotTypeDataList[0].data.push(game.shot_type.jumper.rate);
      tempPlayerSeasonShotTypeDataList[0].data.push(
        game.shot_type.floater.rate
      );
      tempPlayerSeasonShotTypeDataList[0].data.push(game.shot_type.layup.rate);
      tempPlayerSeasonShotTypeDataList[0].data.push(game.shot_type.rim.rate);
      tempPlayerSeasonShotTypeDataList[0].data.push(game.shot_type.dunk.rate);
      tempPlayerSeasonShotTypeDataList[1].data.push(
        Number(game.shot_type.catch_and_shoot.ppp.toFixed(1))
      );
      tempPlayerSeasonShotTypeDataList[1].data.push(
        Number(game.shot_type.jumper.ppp.toFixed(1))
      );
      tempPlayerSeasonShotTypeDataList[1].data.push(
        Number(game.shot_type.floater.ppp.toFixed(1))
      );
      tempPlayerSeasonShotTypeDataList[1].data.push(
        Number(game.shot_type.layup.ppp.toFixed(1))
      );
      tempPlayerSeasonShotTypeDataList[1].data.push(
        Number(game.shot_type.rim.ppp.toFixed(1))
      );
      tempPlayerSeasonShotTypeDataList[1].data.push(
        Number(game.shot_type.dunk.ppp.toFixed(1))
      );

      return tempPlayerSeasonShotTypeDataList;
    },
    getPlayerSeasonOffensiveChooseDataList(game) {
      let tempPlayerSeasonOffensiveChooseDataList = [];

      tempPlayerSeasonOffensiveChooseDataList.push({
        name: "比例",
        data: [],
        color: "#94B7D4",
      });
      tempPlayerSeasonOffensiveChooseDataList.push({
        name: "進攻效率",
        data: [],
        color: "#487AA4",
      });

      tempPlayerSeasonOffensiveChooseDataList[0].data.push(
        game.play_type.iso_ball_handler.play /
          (game.play_type.iso_ball_handler.play +
            game.play_type.iso_ball_others.play)
      );
      tempPlayerSeasonOffensiveChooseDataList[0].data.push(
        game.play_type.iso_ball_others.play /
          (game.play_type.iso_ball_handler.play +
            game.play_type.iso_ball_others.play)
      );
      tempPlayerSeasonOffensiveChooseDataList[0].data.push(
        game.play_type.pnr_ball_handler.play /
          (game.play_type.pnr_ball_handler.play +
            game.play_type.pnr_ball_screener.play +
            game.play_type.pnr_ball_others.play)
      );
      tempPlayerSeasonOffensiveChooseDataList[0].data.push(
        game.play_type.pnr_ball_screener.play /
          (game.play_type.pnr_ball_handler.play +
            game.play_type.pnr_ball_screener.play +
            game.play_type.pnr_ball_others.play)
      );
      tempPlayerSeasonOffensiveChooseDataList[0].data.push(
        game.play_type.pnr_ball_others.play /
          (game.play_type.pnr_ball_handler.play +
            game.play_type.pnr_ball_screener.play +
            game.play_type.pnr_ball_others.play)
      );
      tempPlayerSeasonOffensiveChooseDataList[1].data.push(
        Number(game.play_type.iso_ball_handler.ppp.toFixed(1))
      );
      tempPlayerSeasonOffensiveChooseDataList[1].data.push(
        Number(game.play_type.iso_ball_others.ppp.toFixed(1))
      );
      tempPlayerSeasonOffensiveChooseDataList[1].data.push(
        Number(game.play_type.pnr_ball_handler.ppp.toFixed(1))
      );
      tempPlayerSeasonOffensiveChooseDataList[1].data.push(
        Number(game.play_type.pnr_ball_screener.ppp.toFixed(1))
      );
      tempPlayerSeasonOffensiveChooseDataList[1].data.push(
        Number(game.play_type.pnr_ball_others.ppp.toFixed(1))
      );

      return tempPlayerSeasonOffensiveChooseDataList;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/league/player/advanced";
</style>
